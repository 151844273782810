import React from 'react';
import {Card, Row, Col, Carousel, Icon, Layout} from 'antd';
import {getChildrenToRender} from './utils';
import 'rc-banner-anim/assets/index.css';

const {Meta} = Card;
const {Header, Footer, Sider, Content} = Layout;

class Banner extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {show: false};
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);

    this.next1 = this.next1.bind(this);
    this.prev1 = this.prev1.bind(this);

    // 这边绑定是必要的，这样 `this` 才能在回调函数中使用
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({show: true});
  }
  next() {
    this.slider.slick.slickNext();
  }
  prev() {
    this.slider.slick.slickPrev();
  }

  next1() {
    this.slider1.slick.slickNext();
  }
  prev1() {
    this.slider1.slick.slickPrev();
  }

  render() {
    const {...props} = this.props;
    const {...currentProps} = this.props;
    const {dataSource} = props;
    delete props.dataSource;
    delete props.isMobile;
    const {
      wrapper,
      page,
      BannerAnim
    } = dataSource;
    const Banner0Data = BannerAnim.children.map((item) => {
      return (
        <Card
          onClick={() => {window.open(item.href)}}
          hoverable
          size='small'
          style={{width: '250px', margin: '0 auto', marginBottom: '20px'}}
          cover={<img alt="example" src={item.imgSrc} />}>
          <Meta style={{fontSize: '10px'}} title={<span style={{color: '#0033A1', fontSize: '12px'}}
          >{item.title}</span>} description={item.date} />
        </Card>
      )
    });
    const Banner1Data = BannerAnim.children1.map((item) => {
      return (
        <Card
          onClick={() => {window.open(item.href)}}
          hoverable
          size='small'
          style={{width: '250px', margin: '0 auto', marginBottom: '20px'}}
          cover={<img alt="example" src={item.imgSrc} />}>
          <Meta title={<span style={{color: '#0033A1', fontSize: '12px'}}
          >{item.title}</span>} description={item.date} />
        </Card>
      )
    });
    const Banner2Data = BannerAnim.children2.map((item) => {
      return (
        <Card
          onClick={() => {window.open(item.href)}}
          hoverable
          size='small'
          style={{width: '250px', margin: '0 auto', marginBottom: '20px'}}
          cover={<img alt="example" src={item.imgSrc} />}>
          <Meta title={<span style={{color: '#0033A1', fontSize: '12px'}}
          >{item.title}</span>} description={item.date} />
        </Card>
      )
    });

	  const Banner3Data = BannerAnim.children3.map((item) => {
		  return (
			  <Card
				  onClick={() => {window.open(item.href)}}
				  hoverable
				  size='small'
				  style={{width: '250px', margin: '0 auto', marginBottom: '20px'}}
				  cover={<img alt="example" src={item.imgSrc} />}>
				  <Meta title={<span style={{color: '#0033A1', fontSize: '12px'}}
				  >{item.title}</span>} description={item.date} />
			  </Card>
		  )
	  });

    /*
    let BannerDatas = [];
    for (let i = 0; i < Object.keys(BannerAnim).length; i++) {
      BannerDatas.unshift(BannerAnim[`children${i ? i : ''}`].map((item) => {
        return (
            <Card
                onClick={() => {window.open(item.href)}}
                hoverable
                size='small'
                style={{width: '250px', margin: '0 auto', marginBottom: '20px'}}
                cover={<img alt="example" src={item.imgSrc} />}>
              <Meta style={{fontSize: '10px'}} title={<span style={{color: '#0033A1', fontSize: '12px'}}
              >{item.title}</span>} description={item.date} />
            </Card>
        )
      }))
    }
    {BannerDatas.map(data => (<div style={{textAlign: 'center',background: '#EBEDF0'}}>
      {data}
    </div>))}
    */


    if (this.props.isMobile) {
      return (
        <div>
          <div {...currentProps} {...wrapper}>
            <div {...page}>
              <div {...dataSource.titleWrapper} >
                {dataSource.titleWrapper.children.map(getChildrenToRender)}
              </div>
              <div style={{textAlign: 'center'}}>
                <Icon type="up-circle" style={{fontSize: '30px', color: '#BEC4CC', margin: '0 auto 20px', visibility: 'visible'}} onClick={this.prev1} />
                {/*移动端 暂时隐藏轮播 只有一页*/}
                <Carousel dotPosition='left' style={{background: '#EBEDF0'}} ref={el => (this.slider1 = el)}>
                  {/*第一页数据*/}
                  <div style={{textAlign: 'center',background: '#EBEDF0'}}>
                    {Banner0Data}
                  </div>
                  {/*第二页数据*/}
                  <div style={{textAlign: 'center',background: '#EBEDF0'}}>
                    {Banner1Data}
                  </div>
                  {/*第三页数据*/}
                  <div style={{textAlign: 'center',background: '#EBEDF0'}}>
                    {Banner2Data}
                  </div>

	                {/*第四页数据*/}
	                <div style={{textAlign: 'center',background: '#EBEDF0'}}>
		                {Banner3Data}
	                </div>

                </Carousel>
                <Icon type="down-circle" style={{fontSize: '30px', marginTop: '20px', color: '#BEC4CC', visibility: 'visible'}} onClick={this.next1} />

                {/* <div style={{textAlign: 'center'}}>
                    <Card
                      hoverable
                      size='small'
                      style={{width: '250px', margin: '0 auto'}}
                      cover={<img alt="example" src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/bannerbg1.png" />}
                    >
                      <Meta style={{fontSize: '10px'}} title={<span style={{color: '#0033A1', fontSize: '8px'}}>QuarkChain为北京邮电大学新生进行区块链讲座</span>} description="2019.12.10" />
                    </Card>
                  </div>
                  <div>
                    <Card
                      hoverable
                      size='small'
                      cover={<img alt="example" src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/bannerbg2.png" />}
                    >
                    </Card>
                  </div>*/}

                {/* </div> */}
                {/* <div>

                  </div>
                  <div>
                    <h3>2</h3>
                  </div>
                  <div>
                    <h3>3</h3>
                  </div>
                  <div>
                    <h3>4</h3>
                  </div> */}
                {/* <div style={{marginLeft: '5%'}}> */}

                {/* <Card style={{width: '100%', height: '20%'}} hoverable >
                    <div style={{height: '100%'}}>
                      <div style={{width: '40%', height: '100%', float: 'left'}}>
                        <img
                          style={{width: '100%', height: '90%', marginBottom: '5%'}}
                          alt="example"
                          src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/bannerbg1.png"
                        ></img>
                      </div>
                      <div style={{width: '60%', float: 'right'}}>
                        <span style={{
                          color: '#0033A1', fontSize: '10px'
                        }}>QuarkChain为北京邮电大学新生进行区块链讲座</span>
                        <div style={{marginTop: '7%', color: '#898989'}}>
                          <span style={{color: '#898989'}}>2019.12.10</span>
                          <a href="https://mp.weixin.qq.com/s/dZkxf3XMaCN5dNkq_M4h-g" target="_black" style={{color: '#898989', float: 'right'}}>查看更多>></a>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <Card style={{width: '100%', height: '20%', marginTop: '5%'}} hoverable >
                    <div >
                      <div style={{width: '40%', height: '100%', float: 'left'}}>
                        <img
                          style={{width: '100%', height: '100%'}}
                          alt="example"
                          src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/bannerbg2.png"
                        ></img>
                      </div>
                      <div style={{width: '60%', float: 'right'}}>
                        <span style={{color: '#0033A1', fontSize: '8px'}}>QuarkChain项目进展报告：2020年1月</span>
                        <div style={{marginTop: '20%', color: '#898989'}}>
                          <span style={{color: '#898989'}}>2020.2.1</span>
                          <a href="https://mp.weixin.qq.com/s/13B853SzdDNhjvREKAU67Q" target="_black" style={{color: '#898989', float: 'right'}}>查看更多>></a>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <Card style={{width: '100%', height: '20%', marginTop: '5%'}} hoverable >
                    <div >
                      <div style={{width: '40%', height: '100%', float: 'left'}}>
                        <img
                          style={{width: '100%', height: '100%'}}
                          alt="example"
                          src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/bannerbg3.png"
                        ></img>
                      </div>
                      <div style={{width: '60%', float: 'right'}}>
                        <span style={{color: '#0033A1', fontSize: '8px'}}>QuarkChain开启开发者悬赏开发主网工具可获万元奖励</span>
                        <div style={{marginTop: '7%', color: '#898989'}}>
                          <span style={{color: '#898989'}}>2020.1.16</span>
                          <a href="https://mp.weixin.qq.com/s/-cyJSlebhtjI3ZSxi4Le9w" target="_black" style={{color: '#898989', float: 'right'}}>查看更多>></a>
                        </div>
                      </div>
                    </div>
                  </Card> */}
                {/* <div style={{display: this.state.show ? 'block' : 'none'}}>
                    <Card style={{width: '100%', height: '20%', marginTop: '5%'}} hoverable >
                      {/* <Layout style={{height: '80%'}}>
                    <Sider> */}
                {/* <div >
                    <div style={{width: '40%', height: '100%', float: 'left'}}>
                      <img
                        style={{width: '100%', height: '100%'}}
                        alt="example"
                        src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/gongao4.png"
                      ></img>
                    </div>
                    <div style={{width: '60%', float: 'right'}}>
                      <span style={{color: '#0033A1', fontSize: '8px'}}>QuarkChain夸克链需要更高的舞台 TPS比赛冠军采访</span>
                      <div style={{marginTop: '7%', color: '#898989'}}>
                        <span style={{color: '#898989'}}>2020.1.10</span>
                        <a href="https://mp.weixin.qq.com/s/KVwTNHBjDLjENA9H2BVnLg" target="_black" style={{color: '#898989', float: 'right'}}>查看更多>></a>
                      </div>
                    </div>
                  </div>
                    </Card>

                <Card style={{width: '100%', height: '20%', marginTop: '5%'}} hoverable >
                  <div >
                    <div style={{width: '40%', height: '100%', float: 'left'}}>
                      <img
                        style={{width: '100%', height: '100%'}}
                        alt="example"
                        src="https://sha-ding.oss-cn-beijing.aliyuncs.com/sha-ding/gonggao5.png"
                      ></img>
                    </div>
                    <div style={{width: '60%', float: 'right'}}>
                      <span style={{color: '#0033A1', fontSize: '8px'}}>QuarkChain CMO跨年直播|都说19年是公链的“滑铁卢”,2020公链如何东山再起？</span>
                      <div style={{marginTop: '4%', color: '#898989'}}>
                        <span style={{color: '#898989'}}>2020.1.1</span>
                        <a href="https://mp.weixin.qq.com/s/PoAqGiSNoRpWSFTh89VxBQ" target="_black" style={{color: '#898989', float: 'right'}}>查看更多>></a>
                      </div>
                    </div>
                  </div>
                </Card> */}
                {/* </div>  */}
              </div>
            </div>
          </div >
        </div >

      )
    } else {
      return (
        <div>
          <div {...currentProps} {...wrapper} style={{minWidth:'1056px'}}>
            <div {...page}>
              <div {...dataSource.titleWrapper}>
                {dataSource.titleWrapper.children.map(getChildrenToRender)}
              </div>
              <div style={{background: '#EBEDF0'}}>
                <Row>
                  {/*左右箭头若需要暂时隐藏,可配visibility*/}
                  <Col span={1} style={{textAlign: 'center',visibility:'visible'}}>
                    <Icon type="left-circle" style={{fontSize: '30px', marginTop: '135px', color: '#BEC4CC'}} onClick={this.prev} />
                  </Col>
                  {/*PC端配置新闻*/}
                  <Col span={22}><Carousel ref={el => (this.slider = el)}>

	                  {/*公司动态第1页*/}
	                  <div>
		                  <Row type="flex" align="bottom" justify="space-around">

			                  <Col span={6} > <Card hoverable
			                                        cover={<img alt="example" onClick={() => window.open("https://mp.weixin.qq.com/s/tgZBcDbKX5_0nCFfnVUgEw")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg10.png" />}
			                  >
				                  <Meta title={<div>
                            <span style={{
	                            fontSize: '14px',
	                            display: 'block',
	                            whiteSpace: 'pre-wrap',
	                            height: '63px',
	                            color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
	                            }}>鲨丁信息科技：应对万亿基础设施建设，区块链真的准备好了吗？</span>
				                  </div>} description="2020.7.14" />
			                  </Card></Col>

		                  </Row>
	                  </div>

                    {/*公司动态第2页*/}
                    <div>
                      <Row type="flex" align="bottom" justify="space-around">

                        <Col span={6} > <Card hoverable
                                              cover={<img alt="example" onClick={() => window.open("https://www.thepaper.cn/newsDetail_forward_8038309?from=groupmessage&isappinstalled=0")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg9.png" />}
                        >
                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px',
                              display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>澎湃新闻：来论｜多链异构的核心区块链技术可助中国应对外汇管制问题</span>
                          </div>} description="2020.6.29" />
                        </Card></Col>

                        <Col span={6} > <Card hoverable
                                              cover={<img alt="example" onClick={() => window.open("https://www.thepaper.cn/newsDetail_forward_7307573?from=singlemessage&isappinstalled=0")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg8.png" />}
                        >
                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px',
                              display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>澎湃新闻：云南省大力布局区块链产业，鲨丁科技助力区块链落地</span>
                          </div>} description="2020.5.9" />
                        </Card></Col>

                        <Col span={6} > <Card hoverable
                                              cover={<img alt="example" onClick={() => window.open("https://baijiahao.baidu.com/s?id=1665287167841752857&wfr=spider&for=pc")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg7.jpeg" />}
                        >
                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px',
                              display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>中国高新技术产业导报：陕西省布局区块链技术落地,鲨丁科技成为技术供应商</span>
                          </div>} description="2020.4.29" />
                        </Card></Col>



                      </Row>
                    </div>

                    {/*公司动态第3页*/}
                    <div >
                      <Row type="flex" align="bottom" justify="space-around">

                        <Col span={6} ><Card hoverable
                                             cover={<img alt="example" onClick={() => window.open("https://www.thepaper.cn/newsDetail_forward_6755342")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg3.jpg" />}
                        >
                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px', color: '#0033A1',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              display: 'block', wordWarp: 'break-word', overflow: 'hidden'
                            }}>澎湃新闻：“新基建”下，区块链助力工业互联网快速发展机会巨大</span>
                          </div>} description="2020.3.30" />
                        </Card></Col>

                        <Col span={6} > <Card hoverable
                                              cover={<img alt="example" onClick={() => window.open("https://www.thepaper.cn/newsDetail_forward_6433327?from=groupmessage&isappinstalled=0")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg1.jpg" />}
                        >

                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px',
                              display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>澎湃新闻：疫情暴雨转阴后，用“新基建”+区块链带动新发展的几种可能</span>
                          </div>} description="2020.3.10" />
                        </Card></Col>

                        <Col span={6} > <Card hoverable
                                              cover={<img alt="example" onClick={() => window.open("https://www.cailuw.com/long-post/12996495508161020?id=86")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg6.jpeg" />}
                        >
                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px',
                              display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>财路网：《区块链大讲堂》第78期 — 区块链的落地场景和挑战</span>
                          </div>} description="2020.4.10" />
                        </Card></Col>



                      </Row>
                    </div>

                    {/*公司动态第4页*/}
                    <div >
                      <Row type="flex" align="bottom" justify="space-around">

                        <Col span={6} > <Card hoverable
                                              cover={<img alt="example" onClick={() => window.open("https://www.panewslab.com/zh/articledetails/N6301786.html")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg4.jpg" />}
                        >
                          <Meta title={<div>
                            <span style={{
                              fontSize: '14px', display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>PANews：专家把脉新基建风口，诠释区块链+AWS云的N种可能</span>
                          </div>} description="2020.3.27" />
                        </Card></Col>

                        <Col span={6} offset={1}><Card hoverable
                                                       cover={<img alt="example" onClick={() => window.open("https://mp.weixin.qq.com/s/7-rwc05t--wQiRHGbybCSA")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg5.jpg" />}
                        >
                          <Meta title={
                            <div>
                              <span style={{
                                fontSize: '14px',
                                color: '#0033A1',
                                wordBreak: 'normal',
                                display: 'block',
                                whiteSpace: 'pre-wrap',
                                height: '63px',
                                wordWarp: 'break-word', overflow: 'hidden'
                              }}>链客社区：链客Talk|山东省科学院教授杨耀东: 区块链与国家“新基建”双剑合璧</span>
                            </div>
                          } description="2020.3.27" />
                        </Card></Col>

                        <Col span={6} offset={1}><Card hoverable
                                                       cover={<img alt="example" onClick={() => window.open("https://www.8btc.com/article/569632")} src="https://www-shading-tech.oss-cn-beijing.aliyuncs.com/pics/bannerbg2.png" />}
                        >
                          <Meta title={<div>
                            {/*63px和其他三行新闻同高度*/}
                            <span style={{
                              fontSize: '14px', display: 'block',
                              whiteSpace: 'pre-wrap',
                              height: '63px',
                              color: '#0033A1', wordBreak: 'normal', wordWarp: 'break-word', overflow: 'hidden'
                            }}>巴比特：34万亿！发掘新基建中的“区块链机会”</span>
                          </div>} description="2020.3.15" />
                        </Card></Col>
                      </Row>
                    </div>

                  </Carousel>
                  </Col>
                  <Col span={1} style={{textAlign: 'center',visibility:'visible'}}>
                    <Icon type="right-circle" style={{fontSize: '30px', marginTop: '135px', color: '#BEC4CC'}} onClick={this.next} />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Banner;
